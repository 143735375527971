
import Vue from 'vue'
export default Vue.extend({
    
    data() {
        return {
            userMessage: ''
        }
    },

    methods: {
        async send() {
            if(this.userMessage.trim() != '') {
                await this.$store.dispatch('projectNotes/addMessage', this.userMessage)
                this.userMessage = ''
                await this.$store.dispatch('mail/send', {
                    to: this.$store.getters['projectForm/users'],
                    subject: 'Someone as write a note for project ' + this.$store.getters['projectForm/clientName'],
                    html: '<p>Hello</p><p>New note has been created for client ' + this.$store.getters['projectForm/clientName'] + '</p><a href="https://teams4it-follow.wats-apps.com/project/' + this.$store.getters['projectForm/id'] + '">Check here</a>'
                })
            }
        }
    },

    computed: {
        notes() {
            return this.$store.getters['projectNotes/notes']
        }
    },

})
